import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Layout } from "../components/Layout";

import "swiper/css";
import "swiper/css/navigation";

import { ProductCard } from "../components/ProductCard";
import { Author } from "../components/Author";
import { SliceZone } from "@prismicio/react";
import { components } from "../slices";

const HomepageTemplate = ({ data }) => {
  if (!data) return null;

  const homepage = data.prismicHomepage || {};
  const topMenu = data.prismicTopMenu || {};
  const products = data.allPrismicProducts || {};

  const { lang, type, url } = homepage || {};
  const alternateLanguages = homepage.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages
  };

  const FeaturedProductImageStyle = {backgroundImage: `url(${homepage.data.featured_product.document.data.cover.url})`}

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <section className="Homepage__Hero">
        <div className="container pt-4 pb-5">
          <div className="row align-items-center justify-content-center gy-3 py-3 text-lg-start text-center">
            <div className="col-lg-5 col-md-8 col-sm-10">
              <h1 className="mb-4 pb-lg-2 title-font">
                {homepage.data.display_title}
              </h1>
              <p className="mb-lg-5 mb-4 fs-lg">
                {homepage.data.display_subtitle}
              </p>
              <p className="fs-sm text-muted">In evidenza:</p>
              <h3 className="h5">
                <a className="text-decoration-none" href={homepage.data.featured_product.document.url ?? "#"}>
                  {homepage.data.featured_product.document.data.title ?? ""}
                </a>
              </h3>
              <p>
                <Author author={homepage.data.featured_product.document.data.author ?? ""} />
              </p>
              {/*<div className="d-lg-flex d-none flex-sm-row flex-column justify-content-lg-start justify-content-center">*/}
              {/*  <PrismicLink href={homepage.data.cta_link.url} className="btn btn-lg btn-outline-primary mb-sm-3 mb-2">*/}
              {/*    {homepage.data.cta_label}*/}
              {/*  </PrismicLink>*/}
              {/*</div>*/}
              {homepage.data.featured_product.document.data.categories.map(({ category }, i)=>{
                return(
                  <span key={i} className="badge rounded-pill text-bg-light mb-1 me-1">{category}</span>
                )
              })}
            </div>
            <div className="col-lg-6 col-md-8 offset-lg-1 col-sm-10 position-relative">
              <div className="FeaturedProduct__Image rounded-3 shadow animate__animated animate__fadeInUp" style={FeaturedProductImageStyle}></div>
              <div
                className="InfoCard text-start position-absolute start-0 bottom-0 w-100 px-lg-5 py-lg-5 px-md-5 py-md-4 px-sm-4 py-sm-3 px-4 py-3 animate__animated animate__fadeInUp">
                <div className="pt-sm-0 pt-2 px-sm-4 px-2 bg-white rounded shadow">
                  <div className="row gx-5">
                    <div className="col-sm-8 col-12 position-relative py-sm-3 py-2">
                      <h6 className="mb-1 fs-sm fw-normal text-muted">
                        <Author author={homepage.data.featured_product.document.data.author ?? ""} />
                      </h6><span className="h6 mb-0">{homepage.data.featured_product.document.data.title ?? ""}</span>
                    </div>
                    <div className="col-sm-4 position-relative py-sm-3 py-2">
                      <hr className="hr-vertical position-absolute start-0 top-0 ml-n4 d-sm-block d-none" />
                      <div className="d-flex align-items-center h-100">
                        <a className="btn btn-sm btn-primary w-100" href={homepage.data.featured_product.document.url ?? "#"}>
                          Leggi
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Homepage__Products bg-secondary">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="h2 mt-5 title-font">Ultime edizioni</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Swiper
                modules={[Navigation]}
                slidesPerView={1}
                spaceBetween={50}
                breakpoints={{
                  540: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  960: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
                  1260: {
                    slidesPerView: 4,
                    spaceBetween: 50
                  }
                }}
                navigation
              >
                {products.nodes.map((product) => {
                  return (
                    <SwiperSlide key={product.id}>
                      <ProductCard product={product} />
                    </SwiperSlide>
                  );
                })
                }
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <SliceZone slices={homepage.data.body} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        display_title
        display_subtitle
        featured_product {
          document {
            ... on PrismicProducts {
              url
              data {
                author
                title
                categories {
                  category
                }
                cover {
                  url
                  gatsbyImageData
                  alt
                }
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyJuicerSocialWall
        }
      }
    }
    allPrismicProducts {
      nodes {
        id
        url
        data {
          title
          cover {
            url
            gatsbyImageData
          }
          author
          categories {
            category
          }
          format
          isbn
          series
          total_pages
          external_link {
            url
          }
          short_description {
            text
          }
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
  }
`;

export default withPrismicPreview(HomepageTemplate);
