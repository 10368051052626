import * as React from 'react'
import { graphql } from 'gatsby'
import Vimeo from '@u-wave/react-vimeo';
import { PrismicRichText } from "@prismicio/react";

export const VimeoVideo = ({ slice }) => {
  const video_id = `${slice.primary.vimeo_video_id}`
  return(
    <>
      <section className="vimeo-video-section container">
        <div className="bg-white shadow-sm rounded-3 px-4 px-md-5 py-5 mb-5">
          <h2>{slice.primary.vimeo_video_title}</h2>
          <PrismicRichText field={slice.primary.vimeo_video_description.richText} />
          <Vimeo
            video={video_id}
            responsive
            className="rounded-3 shadow overflow-hidden"
            showByline={false}
            showPortrait={false}
            showTitle={false}
          />
        </div>
      </section>
    </>
)

}

export const query = graphql`
  fragment ProductsDataBodyVimeoVideo on PrismicProductsDataBodyVimeoVideo {
    primary {
      vimeo_video_title
      vimeo_video_description {
        richText
      }
      vimeo_video_id
    }
  }
`
