import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const RichTextSection = ({ slice }) => {
  return(
    <section className="rich-text-section container">
      <div className="bg-white shadow-sm rounded-3 px-4 px-md-5 py-5 mb-5">
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
    </section>
  )

}

export const query = graphql`
  fragment PageDataBodyRichTextSection on PrismicPageDataBodyRichTextSection {
    primary {
      text {
        richText
      }
    }
  }
`
