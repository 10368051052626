import * as React from "react";
import { graphql, Script } from "gatsby";

export const JuicerSocialWall = ({ slice }) => {
  // todo: add support for all data-* attributes
  return (
    <section className="juicer-social-wall py-5">
      <div className="container">
        <h2 className="h2 mb-4 title-font">In bacheca</h2>
        <Script src="https://assets.juicer.io/embed.js" type="text/javascript" />
        <div className="juicer-feed mt-4 rounded-3 shadow" data-feed-id={slice.primary.data_feed_id} data-origin="embed-code" />
      </div>
    </section>)
};

export const query = graphql`
  fragment PageDataBodyJuicerSocialWall on PrismicPageDataBodyJuicerSocialWall {
    primary {
      data_columns
      data_ending_at
      data_feed_id
      data_filter
      data_gutter
      data_interval
      data_overlay
      data_pages
      data_per
      data_starting_at
      data_style
      data_truncate
    }
  }
  fragment HomepageDataBodyJuicerSocialWall on PrismicHomepageDataBodyJuicerSocialWall {
    primary {
      data_columns
      data_ending_at
      data_feed_id
      data_filter
      data_gutter
      data_interval
      data_overlay
      data_pages
      data_per
      data_starting_at
      data_style
      data_truncate
    }
  }
`;
