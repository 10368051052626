import * as React from "react";
import { Author } from "./Author";

export const ProductCard = ({ product }) => {
  const style = { backgroundImage: `url(${product.data.cover.url})` };
  const categories = product.data.categories;
  return (
    <article className="ProductCard card rounded-3 h-100 border-0 shadow-sm mt-3 mb-5">
      <a className="card-img-top position-relative overflow-hidden" style={style} href={product.url} />
      <div className="card-body">
        <a className="d-block text-decoration-none" href={product.url}>
          <h3 className="h5 product-title mb-2 text-truncate">
            {product.data.title}
          </h3>
        </a>
        <Author author={product.data.author} />
      </div>
      <div className="card-footer py-0 border-0">
        <div className="d-flex align-items-center flex-wrap position-relative mb-1 py-3">
          {categories.map(({ category }, i)=>{
            return(
              <span key={i} className="badge rounded-pill text-bg-light mb-1 me-1">{category}</span>
              )
          })}
        </div>
      </div>
    </article>
  );
};
