import * as React from "react";
import { Link, graphql } from "gatsby";
import { PrismicLink, PrismicText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

export const Author = ({ author }) => {
  return (
    <>
        <svg className="me-1" fill="#000000" height="18px" width="18px" version="1.1" id="Capa_1"
             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="-49 -49 588.00 588.00" xmlSpace="preserve">
          <g id="SVGRepo_bgCarrier" strokeWidth="0" />
          <g id="SVGRepo_tracerCarrier" />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M463.957,58.244c-24.452-14.914-57.488-22.795-95.543-22.795c-58.296,0-122.525,18.382-180.205,50.358l-0.404-0.152 c0,0-0.058,0.306-0.089,0.452c-17.46,9.707-34.378,20.547-50.269,32.648C43.843,190.022,17.629,274.582,39.745,319.782 C15.536,361.144,0.622,403.904,0,454.552c46.543-88.696,74.478-160.58,226.143-281.592 c-43.334,9.287-118.233,53.333-168.607,120.173c-4.753-39.1,26.141-102.236,94.954-154.624c8.713-6.635,17.774-12.836,27.022-18.72 c-7.226,22.454-4.923,16.869-19.916,48.353c22.171-20.984,36.769-33.933,58.658-69.896c28.42-13.96,57.981-24.394,87.291-30.7 c-4.85,16.078-14.081,43.326-26.578,64.868c0,0,31.718-6.799,57.932-5.263c-14.323,15.803-27.209,32.898-40.278,50.375 c-17.897,23.926-36.398,48.668-60.09,71.697c-2.852,2.773-5.577,5.287-8.342,7.881c-36.406-3.485-60.453,10.128-82.982,34.175 c17.768-8.284,41.637-15.106,56.599-11c-27.573,22.487-70.995,52.128-106.674,49.688c-6.766,10.297-7.194,10.613-14.631,23.052 c57.875,14.406,130.567-44.4,173.343-85.996c25.083-24.394,44.19-49.929,62.661-74.615c38.038-50.851,70.889-94.758,143.759-111.467 L490,74.127L463.957,58.244z" />
          </g>
        </svg>
        <span className="fs-sm" href="#">{author}</span>
    </>
  );
};
